import { createSlice } from "@reduxjs/toolkit"
import t from "typy"

const initialState = {
  records: [],
}

const cyclesSlice = createSlice({
  name: "cycles",
  initialState,
  reducers: {
    setData(state, action) {
      state.records = action.payload
    },
  },
})

export const { setData } = cyclesSlice.actions

// helpers
const getCyclesState = (state) => state.cycles
export const getCycles = (state) =>
  t(getCyclesState(state), "records").safeArray
export const getActiveCycle = (state) =>
  getCycles(state).find((c) => c.is_active === "1")
export const getSeasons = (state) =>
  getCycles(state)
    .map((c) => c.season)
    .filter((value, index, self) => self.indexOf(value) === index)

export default cyclesSlice.reducer
