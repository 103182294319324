import { Alert, AlertTitle } from "@mui/material"
import { useSelector } from "react-redux"
import * as auth from "../../slices/authSlice"

const RequireAuth = ({ children }) => {
  const isAuthenticated = useSelector(auth.isAuthenticated)

  if (!isAuthenticated) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        You must be signed in
      </Alert>
    )
  }

  return children
}

export default RequireAuth
