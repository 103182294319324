import React, { useState } from "react"
import { Stack, Alert, AlertTitle, TextField, Button } from "@mui/material"
import Title from "../ui/Title"
import stcApi from "../../api/stc"
import { strEncode, getAxiosErrorData } from "../../common/tools"

const UserRegister = () => {
  const [email, setEmail] = useState("")
  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const registerUser = async (email) => {
    setInFlight(true)
    try {
      const result = await stcApi.get("/user/register/" + strEncode(email))
      setApiResponse(result.data.data)
      setInFlight(false)
      setEmail("")
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!email) {
      return
    }

    registerUser(email)
  }

  return (
    <Stack spacing={3}>
      <Title>User Registration</Title>
      <Alert severity="info">
        <AlertTitle>
          Please provide your STC email below. We will send you link to register
          or reset password.
        </AlertTitle>
        If you are not STC member, you can still register for 1-month trial.
      </Alert>
      {apiResponse && apiResponse.message && (
        <Alert severity={apiResponse.success ? "success" : "error"}>
          <strong>{apiResponse.message}</strong>
        </Alert>
      )}
      <Stack
        component="form"
        direction="row"
        onSubmit={onSubmit}
        spacing={1}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          autoFocus
          fullWidth
          label="Your Email Address"
          value={email}
          disabled={inFlight}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" variant="contained" disabled={!email || inFlight}>
          Submit
        </Button>
      </Stack>
    </Stack>
  )
}

export default UserRegister
