import * as React from "react"
import { Outlet } from "react-router-dom"
import { Box, Container } from "@mui/material"
import Footer from "./Footer"
import Header from "./Header"
import { Announcement } from "./Announcement"

const Layout = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Box mt={3}>
          <Announcement />
          <Outlet />
        </Box>
        <Footer />
      </Container>
    </>
  )
}

export default Layout
