import { configureStore } from "@reduxjs/toolkit"

import authReducer from "./slices/authSlice"
import cycleReducer from "./slices/cycleSlice"
import cyclesSlice from "./slices/cyclesSlice"
import playersReducer from "./slices/playersSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    cycle: cycleReducer,
    cycles: cyclesSlice,
    players: playersReducer,
  },
})

export default store
