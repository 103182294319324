import { createSlice } from "@reduxjs/toolkit"
import { setLocalUserData, clearLocalUserData } from "../common/user"
import { fetchAllPlayers } from "./playersSlice"
import t from "typy"

const initialState = {
  user_id: null,
  username: null,
  roles: [],
  authToken: null,
  expiration: null,
  playerData: null, // data for associated player
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn(state, action) {
      // check expiration

      state.user_id = action.payload.user_id
      state.username = action.payload.username
      state.authToken = action.payload.auth_token
      state.expiration = action.payload.expiration
      state.roles = action.payload.roles

      // save data in local storage
      setLocalUserData(action.payload)
    },
    signOut() {
      clearLocalUserData()
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPlayers.fulfilled, (state, action) => {
      let player = action.payload.find(
        (player) => player.email === state.username
      )
      if (player) {
        state.playerData = player
      }
    })
  },
})

export const { signIn, signOut } = authSlice.actions

// helpers
const getAuthState = (state) => state.auth
export const isAuthenticated = (state) =>
  t(getAuthState(state), "roles").safeArray.length > 0
export const isAdmin = (state) =>
  t(getAuthState(state), "roles").safeArray.includes("admin")
export const getSignedPlayerData = (state) =>
  t(getAuthState(state), "playerData").safeObjectOrEmpty
export const getSignedPlayerId = (state) =>
  t(getSignedPlayerData(state), "id").safeString
export const getUserId = (state) => t(getAuthState(state), "user_id").safeNumber
export const getUsername = (state) =>
  t(getAuthState(state), "username").safeString
export const getFullName = (state) => {
  let playerData = t(getAuthState(state), "playerData").safeObjectOrEmpty
  let fn = t(playerData, "first_name").safeString
  let ln = t(playerData, "last_name").safeString
  return fn && ln ? `${fn} ${ln}` : ""
}

export default authSlice.reducer
