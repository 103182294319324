import { createSlice } from "@reduxjs/toolkit"
import t from "typy"
import { isAuthenticated, getSignedPlayerId } from "./authSlice"
import { getCycles } from "./cyclesSlice"

const initialState = {
  id: null,
  season: "",
  label: "",
  slug: "",
  nextGroupId: 1,
  mode: null, // add - add new cycle, edit - edit cycle, view - view cycle
  groups: [],
  matches: [],
  playersFlux: {},
  resultToPoints: {},
  focusedView: false,
}

const cycleSlice = createSlice({
  name: "cycle",
  initialState,
  reducers: {
    addGroup(state) {
      state.groups.push({
        id: state.nextGroupId,
        label: "Group ",
      })
      state.nextGroupId++
    },
    removeGroup(state, action) {
      let id = action.payload.id
      state.groups = state.groups.filter((group) => group.id !== id)
    },
    updateGroup(state, action) {
      let newGroup = action.payload
      state.groups = state.groups.map((group) => {
        return group.id == newGroup.id ? newGroup : group
      })
    },
    setGroupView(state, action) {
      // action: { groupId: <groupId>, view: <value> }
      let { groupId, view } = action.payload
      state.groups = state.groups.map((group) => {
        return group.id === groupId ? { ...group, view } : group
      })
    },
    setMode(state, action) {
      state.mode = action.payload
    },
    setCycleData(state, action) {
      let { id, season, label, groups, matches, playersFlux, resultToPoints } =
        action.payload
      state.id = id
      state.season = season
      state.label = label
      state.groups = groups
      state.matches = matches
      state.playersFlux = playersFlux
      state.resultToPoints = resultToPoints
    },
    setGroups(state, action) {
      state.groups = action.payload.groups
    },
    setMatches(state, action) {
      state.matches = action.payload.matches
    },
    setLabel(state, action) {
      state.label = action.payload.label
    },
    setSlug(state, action) {
      state.slug = action.payload.slug
    },
    setResultToPoints(state, action) {
      state.resultToPoints = action.payload.resultToPoints
    },
    setFocusedView(state, action) {
      state.focusedView = t(action.payload).safeBoolean
    },
    resetGroupsView(state) {
      state.groups = []
      state.mode = "view"
    },
  },
})

export const {
  addGroup,
  removeGroup,
  updateGroup,
  setGroups,
  setMatches,
  setGroupView,
  setMode,
  setCycleData,
  setLabel,
  setSlug,
  setFocusedView,
  resetGroupsView,
} = cycleSlice.actions

// helpers
const getCycleState = (state) => state.cycle
export const getId = (state) => t(getCycleState(state), "id").safeNumber
export const getSeason = (state) => t(getCycleState(state), "season").safeString
export const getLabel = (state) => t(getCycleState(state), "label").safeString
export const getGroups = (state) => t(getCycleState(state), "groups").safeArray
export const getMatches = (state) =>
  t(getCycleState(state), "matches").safeArray
export const getResultToPoints = (state) =>
  t(getCycleState(state), "resultToPoints").safeObject
export const getPointsForWin = (state) =>
  t(getResultToPoints(state), "w").safeNumber || 0
export const getPointsForLoss = (state) =>
  t(getResultToPoints(state), "l").safeNumber || 0
export const getPointsForDefault = (state) =>
  t(getResultToPoints(state), "d").safeNumber || 0
export const getPlayers = (state) =>
  getGroups(state)
    .map((group) => group.players)
    .flat()
export const getPlayerIds = (state) =>
  getPlayers(state).map((player) => player.id)
export const isParticipating = (state) =>
  isAuthenticated(state) &&
  getPlayerIds(state).includes(getSignedPlayerId(state))
export const isFocusedView = (state) =>
  t(getCycleState(state), "focusedView").safeBoolean
export const getMode = (state) => t(getCycleState(state), "mode").safeString
export const getJoiningPlayers = (state) =>
  t(getCycleState(state), "playersFlux.joining").safeArray
export const getLeavingPlayers = (state) =>
  t(getCycleState(state), "playersFlux.leaving").safeArray
export const isActive = (state) =>
  t(
    getCycles(state).find((c) => parseInt(c.id) === getId(state)),
    "is_active"
  ).safeString === "1"

export default cycleSlice.reducer
