import * as React from "react"
import { Typography } from "@mui/material"

const Title = ({ children }) => {
  return (
    <Typography
      variant="h5"
      component="div"
      sx={{
        margin: 0,
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
    >
      {children}
    </Typography>
  )
}

export default Title
