import { TableRow, Stack, Badge, Tooltip } from "@mui/material"
import { ExitToApp } from "@mui/icons-material"
import PlayerCell from "./PlayerCell"
import StyledCell from "./StyledCell"
import { fName, lName } from "../../common/player"
import * as playersSlice from "../../slices/playersSlice"
import * as cycle from "../../slices/cycleSlice"
import { useSelector } from "react-redux"

const PlayerRow = ({ id, players, groupId, points }) => {
  const playerData = players.find((player) => player.id === id)
  let isTrial = playerData.is_member === "N"

  // turn trial off after September 1st
  const currentMonth = (new Date()).getMonth() + 1  // months are 0-indexed
  if (currentMonth >= 9) {
    isTrial = false
  }

  // get user id of this player
  const allPlayers = useSelector(playersSlice.getPlayers)
  const userId = allPlayers.find((p) => p.id === id)?.user_id || null

  const leavingPlayers = useSelector(cycle.getLeavingPlayers)
  const isLeaving = leavingPlayers.map((p) => p.user_id).includes(userId)

  const PlayerName = () => (
    <Stack>
      <div>{fName(playerData)}</div>
      <div>
        {lName(playerData)} ({playerData.seed})
      </div>
    </Stack>
  )

  const WrapTrial = ({ children }) => {
    if (isTrial) {
      return (
        <Badge badgeContent="trial" color="primary">
          {children}
        </Badge>
      )
    }
    return children
  }

  const renderTags = () => {
    return (
      <Stack
        spacing={0}
        sx={{
          padding: "4px",
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
      >
        {isLeaving && (
          <Tooltip title="Will not play in the next cycle">
            <ExitToApp fontSize="small" />
          </Tooltip>
        )}
      </Stack>
    )
  }

  return (
    <TableRow>
      <StyledCell sx={{ fontWeight: "bold" }}>
        <WrapTrial>
          <PlayerName />
        </WrapTrial>
        {renderTags()}
      </StyledCell>
      {players.map((player) => (
        <PlayerCell
          key={player.id}
          rowPlayer={playerData}
          colPlayer={player}
          groupId={groupId}
          points={points}
        />
      ))}
    </TableRow>
  )
}

export default PlayerRow
