import { styled } from "@mui/material/styles"
import { TableCell } from "@mui/material"

// customized table cell for groups table
const StyledCell = styled(TableCell)(({ theme }) => ({
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[400],
  position: "relative",
}))

export default StyledCell
