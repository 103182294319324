import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  Stack,
  Alert,
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Title from "../ui/Title"
import stcApi from "../../api/stc"
import { strDecode, getAxiosErrorData } from "../../common/tools"

const UserResetPassword = () => {
  const [userStatus, setUserStatus] = useState(null) // response from user check to determine if we need sending contact data
  const [userData, setUserData] = useState("") // user data after successful password reset

  const [errMessage, setErrMessage] = useState(null)
  const [inFlight, setInFlight] = useState(null)

  const [newPassword, setNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [newPasswordVerify, setNewPasswordVerify] = useState("")
  const [showPasswordVerify, setShowPasswordVerify] = useState(false)

  // contact
  const ntrp_levels = ["2.0", "2.5", "3.0", "3.5", "4.0", "4.5+"]
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [gender, setGender] = useState("")
  const [phone, setPhone] = useState("")
  const [ntrp, setNtrp] = useState(ntrp_levels[0])

  const params = useParams()
  const token = params.token
  const emailHash = params.emailHash
  const email = strDecode(emailHash)

  useEffect(() => {
    checkUser()
  }, [])

  const checkUser = async () => {
    setErrMessage(null)
    setUserStatus(null)
    setInFlight(true)
    try {
      const result = await stcApi.get(`/user/register/${emailHash}/${token}`)
      setUserStatus(result.data)
      setInFlight(null)
    } catch (err) {
      let data = getAxiosErrorData(err)
      setErrMessage(data.message)
      setInFlight(null)
    }
  }

  const resetPassword = async (data) => {
    setErrMessage(null)
    setInFlight(true)
    try {
      const result = await stcApi.post(
        `/user/register/${emailHash}/${token}`,
        data
      )
      setUserData(result.data)
      setInFlight(null)
    } catch (err) {
      let data = getAxiosErrorData(err)
      setErrMessage(data.message)
      setInFlight(null)
    }
  }

  const onFormSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid()) {
      return
    }

    const data = needsContact()
      ? {
          password: newPassword,
          contact: {
            first_name: firstName,
            last_name: lastName,
            gender,
            phone,
            ntrp_level: ntrp,
          },
        }
      : { password: newPassword }

    resetPassword(data)
  }

  const needsContact = () => {
    return userStatus && userStatus.data.needs_contact
  }

  const validatePassword = () => {
    // return true if both passwords have non-empty value and are same
    if (!newPassword || !newPasswordVerify) {
      return false
    }
    return newPassword === newPasswordVerify
  }

  const isFormValid = () => {
    // check if required fields are not empty
    if (needsContact()) {
      return (
        validatePassword() && firstName && lastName && phone && gender && ntrp
      )
    } else {
      return validatePassword()
    }
  }

  const renderPasswordForm = () => {
    return (
      <>
        <Alert severity="info" icon={false}>
          Set the new password for user with email <strong>{email}</strong>{" "}
          below.
        </Alert>
        <TextField
          label="New Password"
          required
          value={newPassword}
          disabled={inFlight}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="New Password (Verify)"
          required
          value={newPasswordVerify}
          disabled={inFlight}
          type={showPasswordVerify ? "text" : "password"}
          onChange={(e) => setNewPasswordVerify(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => setShowPasswordVerify(!showPasswordVerify)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPasswordVerify ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {newPassword && newPasswordVerify && !validatePassword() && (
          <Alert severity="warning">Passwords don't match</Alert>
        )}
      </>
    )
  }

  const renderContactForm = () => {
    return (
      <>
        <Alert severity="info" icon={false}>
          It seems we don't have any contact details for this email (not STC
          member?). Please provide contact info below.
        </Alert>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            label="First Name"
            required
            value={firstName}
            disabled={inFlight}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            label="Last Name"
            required
            value={lastName}
            disabled={inFlight}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            label="Phone"
            required
            value={phone}
            disabled={inFlight}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="ntrp-level-label">NTRP Level</InputLabel>
              <Select
                labelId="ntrp-level-label"
                value={ntrp}
                label="NTRP Level"
                onChange={(e) => setNtrp(e.target.value)}
              >
                {ntrp_levels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <RadioGroup
          row
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <FormControlLabel value="F" control={<Radio />} label="Female" />
          <FormControlLabel value="M" control={<Radio />} label="Male" />
        </RadioGroup>
      </>
    )
  }

  const renderForm = () => {
    // don't show for if we don't have user status or it has already been submitted (i.e. we have user data)
    if (!userStatus || !userStatus.data || userData) {
      return null
    }

    return (
      <>
        <Stack
          component="form"
          direction="column"
          onSubmit={onFormSubmit}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          {needsContact() && renderContactForm()}
          {renderPasswordForm()}
          <Box>
            <Button
              type="submit"
              variant="contained"
              disabled={!isFormValid() || inFlight}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </>
    )
  }

  return (
    <Stack spacing={2}>
      <Title>Set Password</Title>
      {errMessage && (
        <Alert severity="error">
          <strong>{errMessage}</strong>
        </Alert>
      )}
      {userData && userData.success && (
        <Alert severity="success">
          Password for user with email <strong>{email}</strong> has been set.
        </Alert>
      )}
      {renderForm()}
    </Stack>
  )
}

export default UserResetPassword
