import stcApi from "../../api/stc"
import { useEffect, useState } from "react"
import JoiningLeagueInfo from "../ui/JoiningLeagueInfo"
import { Stack } from "@mui/material"
import JoiningPlayersList from "../ui/JoiningPlayersList"
import { useNavigate } from "react-router-dom"

const VirtualCycle = () => {
  const navigate = useNavigate()
  const [joiningPlayers, setJoiningPlayers] = useState()

  const fetchCycleData = async () => {
    try {
      const result = await stcApi.get(`/cycle/active`)
      if (result) {
        setJoiningPlayers(result?.data?.data?.joiningPlayers ?? [])
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchCycleData()
  }, [])

  return (
    <Stack spacing={2}>
      <JoiningLeagueInfo navigate={navigate} />
      <JoiningPlayersList players={joiningPlayers} />
    </Stack>
  )
}

export default VirtualCycle
