import { Alert, Button } from "@mui/material"

const JoiningLeagueInfo = ({ navigate }) => {
  if (!navigate) {
    return null
  }

  return (
    <Alert severity="info" icon={false}>
      <strong>Interested in joining or trying out the league?</strong> Please
      follow the instructions{" "}
      <Button onClick={(e) => navigate("/docs/joining-league")}>here</Button>
    </Alert>
  )
}

export default JoiningLeagueInfo
