import { Alert, Button, Stack, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { getAxiosErrorData } from "../../common/tools"
import stcApi from "../../api/stc"

const Email = ({ groupId }) => {
  const [body, setBody] = useState("")
  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const sendEmail = async () => {
    setInFlight(true)
    setApiResponse(null)
    try {
      const result = await stcApi.post(`/group/${groupId}/email`, {
        emailBody: body,
      })
      setApiResponse(result.data.data)
      setInFlight(false)
      setBody("")
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    sendEmail()
  }

  if (apiResponse !== null) {
    return (
      <Alert severity={apiResponse.success ? "success" : "error"}>
        <strong>{apiResponse.message}</strong>
      </Alert>
    )
  }

  return (
    <Stack
      component="form"
      onSubmit={onSubmit}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      <Alert severity="info">
        <strong>Email will be sent to all players in this group.</strong>
      </Alert>
      <TextField
        label="Email Body"
        multiline
        minRows={10}
        value={body}
        disabled={inFlight}
        onChange={(e) => setBody(e.target.value)}
      />
      <Box>
        <Button type="submit" variant="contained" disabled={!body || inFlight}>
          Send
        </Button>
      </Box>
    </Stack>
  )
}

export default Email
