import * as vars from "./vars"

export const getLocalUserData = () => {
  let storageData = localStorage.getItem(vars.localStorageUserKey)
  return storageData ? JSON.parse(storageData) : null
}

export const setLocalUserData = (data) => {
  localStorage.setItem(vars.localStorageUserKey, JSON.stringify(data))
}

export const clearLocalUserData = () => {
  localStorage.removeItem(vars.localStorageUserKey)
}
