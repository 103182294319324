import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  Alert,
  Grid,
  Stack,
  Box,
  Button,
  Typography,
  Divider,
  Switch,
} from "@mui/material"
import { getAxiosErrorData } from "../../common/tools"
import stcApi from "../../api/stc"
import Title from "../ui/Title"
import Group from "../group/Group"
import * as cycle from "../../slices/cycleSlice"
import * as auth from "../../slices/authSlice"
import CycleSelector from "../ui/CycleSelector"
import JoiningLeagueInfo from "../ui/JoiningLeagueInfo"
import JoiningPlayersList from "../ui/JoiningPlayersList"
import { formatPoints } from "../../common/utils"

const Cycle = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const params = useParams()
  const season = params.season
  const cycleSlug = params.cycleSlug

  const [error, setError] = useState(null)
  const [isJoining, setIsJoining] = useState(null)

  const isAuthenticated = useSelector(auth.isAuthenticated)
  const isAdmin = useSelector(auth.isAdmin)
  const isParticipating = useSelector(cycle.isParticipating)
  const isFocusedView = useSelector(cycle.isFocusedView)
  const isActive = useSelector(cycle.isActive)
  const viewMode = useSelector(cycle.getMode)
  const groups = useSelector(cycle.getGroups)
  const signedPlayerId = useSelector(auth.getSignedPlayerId)
  const signedUserId = useSelector(auth.getUserId)
  const joiningPlayers = useSelector(cycle.getJoiningPlayers)
  const label = useSelector(cycle.getLabel)
  const cycleId = useSelector(cycle.getId)

  const pointsForWin = useSelector(cycle.getPointsForWin)
  const pointsForLoss = useSelector(cycle.getPointsForLoss)
  const pointsForDefault = useSelector(cycle.getPointsForDefault)

  const fetchCycleData = async () => {
    setError(null)
    try {
      const result = await stcApi.get(`/cycle/${season}/${cycleSlug}`)
      dispatch(cycle.setCycleData(result.data.data.cycleData))
    } catch (err) {
      const data = getAxiosErrorData(err)
      setError(data.message)
    }
  }

  const setActive = async () => {
    setInFlight(true)
    setApiResponse(null)
    try {
      const result = await stcApi.put(`/cycle/active/${cycleId}`)
      setApiResponse(result.data.data)
      setInFlight(false)
      // @todo - refetch cycles
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  // reset cycle groups on initial load
  useEffect(() => {
    dispatch(cycle.resetGroupsView())
  }, [])

  useEffect(() => {
    fetchCycleData()
  }, [season, cycleSlug])

  useEffect(() => {
    if (joiningPlayers !== null) {
      setIsJoining(
        isAuthenticated &&
          joiningPlayers.map((p) => parseInt(p.user_id)).includes(signedUserId)
      )
    }
  }, [joiningPlayers])

  const renderJoiningPlayers = () => {
    if (isFocusedView || !joiningPlayers || !joiningPlayers.length) {
      return null
    }

    return <JoiningPlayersList players={joiningPlayers} />
  }

  const renderRightPanel = () => {
    return (
      <Stack spacing={2}>
        {!isAuthenticated && (
          <Box>
            Please <Button onClick={(e) => navigate("/signin")}>Sign In</Button>{" "}
            for customized view.
          </Box>
        )}
        {renderJoiningPlayers()}
      </Stack>
    )
  }

  const renderPlayerTools = () => {
    // nothing to show if not participating in the cycle
    if (!isParticipating) {
      return null
    }

    return (
      <Stack
        direction="row"
        spacing={0}
        mb={3}
        alignItems="center"
        justifyContent="end"
      >
        <Box>Focused View</Box>
        <Switch
          checked={isFocusedView}
          onChange={(e) => dispatch(cycle.setFocusedView(!isFocusedView))}
        />
      </Stack>
    )
  }

  const renderGroups = () => {
    // if player is participating and focused view is on, show only his/her group
    let displayGroups =
      isParticipating && isFocusedView
        ? groups.filter((group) =>
            group.players.map((player) => player.id).includes(signedPlayerId)
          )
        : groups

    return displayGroups.map((group) => <Group key={group.id} data={group} />)
  }

  const renderAdminTools = () => {
    if (!isAdmin) {
      return null
    }
    return (
      <Box>
        {!isActive && (
          <Button onClick={setActive} disabled={inFlight}>
            Set Active
          </Button>
        )}
      </Box>
    )
  }

  const renderCycle = () => {
    let smLeft = isFocusedView ? 12 : 8
    let smRight = isFocusedView ? 12 : 4
    return (
      <>
        {renderPlayerTools()}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={smLeft}>
            <Stack mb={2} direction="row" alignItems="center">
              <Box flexGrow={1}>
                <Title>
                  {label} {!isActive && " (closed)"}
                </Title>
              </Box>
              {renderAdminTools()}
              <CycleSelector />
            </Stack>
            <Stack spacing={2}>
              {isFocusedView && (
                <Alert severity="info">
                  Only your group is shown. Turn Focused View off to display all
                  groups.
                </Alert>
              )}
              {!isParticipating && isJoining === false && (
                <JoiningLeagueInfo navigate={navigate} />
              )}
              {renderGroups()}
              <Stack
                direction="row"
                spacing={2}
                pt={1}
                sx={{ fontSize: "90%", color: "#555" }}
              >
                <Typography sx={{ fontWeight: "bolder" }}>Scoring:</Typography>
                <Typography>
                  <strong>W</strong>in / Default <strong>W</strong>in (
                  <strong>*</strong>): {formatPoints(pointsForWin)}
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography>
                  <strong>L</strong>oss: {formatPoints(pointsForLoss)}
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography>
                  <strong>D</strong>efault: {formatPoints(pointsForDefault)}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={smRight}>
            <Box ml={1}>{renderRightPanel()}</Box>
          </Grid>
        </Grid>
      </>
    )
  }

  // @todo - should render this component only when this is true
  if (viewMode !== "view") {
    return null
  }

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      {apiResponse && (
        <Alert severity="info">{JSON.stringify(apiResponse, null, 2)}</Alert>
      )}
      {groups && renderCycle()}
    </>
  )
}

export default Cycle
