import { Alert, TextField, IconButton, InputAdornment } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useEffect, useState } from "react"

const PasswordConfirm = ({ onNewValue, isDisabled = false }) => {
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordVerify, setNewPasswordVerify] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordVerify, setShowPasswordVerify] = useState(false)

  const validatePassword = () => {
    // return true if both passwords have the same, non-empty value
    if (!newPassword || !newPasswordVerify) {
      return false
    }
    return newPassword === newPasswordVerify
  }

  // update caller when password changes and value matches
  useEffect(() => {
    if (validatePassword()) {
      onNewValue(newPassword)
    }
  }, [newPassword, newPasswordVerify])

  return (
    <>
      <TextField
        label="New Password"
        required
        value={newPassword}
        disabled={isDisabled}
        type={showPassword ? "text" : "password"}
        onChange={(e) => setNewPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="New Password (Verify)"
        required
        value={newPasswordVerify}
        disabled={isDisabled}
        type={showPasswordVerify ? "text" : "password"}
        onChange={(e) => setNewPasswordVerify(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => setShowPasswordVerify(!showPasswordVerify)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPasswordVerify ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {newPassword && newPasswordVerify && !validatePassword() && (
        <Alert severity="warning">Passwords don't match</Alert>
      )}
    </>
  )
}

export default PasswordConfirm
