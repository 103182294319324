import { Alert, Stack, Box, Link, Divider } from "@mui/material"
import { useSelector } from "react-redux"
import { fullName } from "../../common/player"
import { Phone, EmailOutlined } from "@mui/icons-material"
import { getPlayers } from "../../slices/playersSlice"

const Contacts = ({ players }) => {
  const allPlayers = useSelector(getPlayers)
  const getPlayerData = (playerId) =>
    allPlayers.find((player) => player.id === playerId)

  const renderPlayerContact = (player) => {
    let data = getPlayerData(player.id)
    let displayName = fullName(player)
    if (!data) {
      return (
        <Alert severity="error">
          Cannot get contact for <strong>{displayName}</strong>
        </Alert>
      )
    }
    return (
      <Stack direction="column" spacing={1} key={player.id}>
        <Box sx={{ fontWeight: "bold" }}>{displayName}</Box>
        <Box>
          <Stack ml={2} direction="row" spacing={1}>
            <Phone color="action" />
            <Link href={`tel:${data.phone}`}>{data.phone}</Link>
          </Stack>
          <Stack ml={2} direction="row" spacing={1}>
            <EmailOutlined color="action" />
            <Link href={`mailto:${data.email}`} target="blank">
              {data.email}
            </Link>
          </Stack>
        </Box>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <Divider />
      {players.map((player) => renderPlayerContact(player))}
    </Stack>
  )
}

export default Contacts
