import { Alert } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getActiveCycle } from "../../slices/cyclesSlice"
import VirtualCycle from "../cycle/VirtualCycle"

const Home = () => {
  const navigate = useNavigate()
  const activeCycle = useSelector(getActiveCycle)
  const isVirtual = activeCycle?.is_virtual === "1"

  // redirect to active cycle if we have one
  useEffect(() => {
    if (activeCycle && !isVirtual) {
      navigate(`/${activeCycle.season}/${activeCycle.slug}`)
    }
  }, [activeCycle, isVirtual])

  if (isVirtual) {
    return <VirtualCycle />
  }

  if (!activeCycle) {
    return <Alert severity="warning">There is no active cycle.</Alert>
  }

  return null
}

export default Home
