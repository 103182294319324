// import { Alert, Box } from "@mui/material"

export const Announcement = () => {
  return null
  // return (
  //   <Box mb={2}>
  //     <Alert severity="error" variant="filled" icon={false}>
  //       <strong>STC League will resume in April 2024.</strong>
  //       <Box>
  //         Please register below if you wish to play in the next cycle starting
  //         April 1st. There will be smaller groups of 3-4 players.
  //       </Box>
  //     </Alert>
  //   </Box>
  // )
}
