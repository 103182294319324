import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"
import Layout from "./layout/Layout"
import UserRegister from "./user/UserRegister"
import UserResetPassword from "./user/UserResetPassword"
import SignIn from "./auth/SignIn"
import Admin from "./admin/Admin"
import PlayersList from "./admin/PlayersList"
import NewCycle from "./admin/NewCycle"
import AdminEmail from "./admin/AdminEmail"
import { getLocalUserData } from "../common/user"
import * as authSlice from "../slices/authSlice"
import { fetchAllPlayers } from "../slices/playersSlice"
import * as cycles from "../slices/cyclesSlice"
import Error from "./ui/Error"
import Cycle from "./cycle/Cycle"
import Home from "../components/home/Home"
import UserProfile from "./user/UserProfile"
import stcApi from "../api/stc"
import Docs from "./docs/Docs"
import UserChangePassword from "./user/UserChangePassword"
import Trials from "./admin/Trials"

const App = () => {
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)

  const fetchCycles = async () => {
    try {
      const result = await stcApi.get("/cycles")
      let data = result.data.data.cycles
      dispatch(cycles.setData(data))
    } catch (err) {
      dispatch(cycles.setData([]))
    }
  }

  useEffect(() => {
    // auto-signin if there is authenticated user in local storage
    let userData = getLocalUserData()
    if (userData) {
      dispatch(authSlice.signIn(userData))
    }
  }, [])

  // load players list when user signs in
  useEffect(() => {
    if (authState.authToken) {
      dispatch(fetchAllPlayers())
    }
  }, [authState.authToken])

  // fetch cycles
  useEffect(() => {
    fetchCycles()
  }, [])

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/error" exact element={<Error />} />
          <Route path="/signin" exact element={<SignIn />} />
          <Route path="/user/profile" exact element={<UserProfile />} />
          <Route path="/user/register" exact element={<UserRegister />} />
          <Route
            path="/user/change-password"
            exact
            element={<UserChangePassword />}
          />
          <Route
            path="/user/reset-password/:emailHash/:token"
            exact
            element={<UserResetPassword />}
          />
          <Route path="/docs" exact element={<Docs />} />
          <Route path="/docs/:section" exact element={<Docs />} />
          <Route path="/admin" exact element={<Admin />}>
            <Route path="players" exact element={<PlayersList />} />
            <Route path="new-cycle" exact element={<NewCycle />} />
            <Route path="email" exact element={<AdminEmail />} />
            <Route path="trials" exact element={<Trials />} />
          </Route>
          <Route path="/:season/:cycleSlug" exact element={<Cycle />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
