import { useSelector } from "react-redux"
import {
  Alert,
  Paper,
  Stack,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import * as auth from "../../slices/authSlice"
import { getPlayers, getPlayerIds } from "../../slices/playersSlice"
import { useState } from "react"
import stcApi from "../../api/stc"
import { getAxiosErrorData } from "../../common/tools"

const Edit = ({ data }) => {
  const normalizedData = {
    ...data,
    players: data.players.map((player) => ({
      ...player,
      seed: parseInt(player.seed),
    })),
  }

  const isAdmin = useSelector(auth.isAdmin)
  const [groupData, setGroupData] = useState(normalizedData)
  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const blankPlayer = {
    id: 0,
    first_name: "",
    last_name: "__select__",
    ntrp_level: 0,
  }

  const players = [...useSelector(getPlayers), blankPlayer]
  const playerIds = [...useSelector(getPlayerIds), blankPlayer.id]

  // prepare updated players list (remove blank players)
  const newPlayers = groupData.players.filter(
    (player) => player.id !== blankPlayer.id
  )

  const updateGroupLabel = (e) => {
    setGroupData({
      ...groupData,
      label: e.target.value,
    })
  }

  const formatPlayerLabel = (player, seed) => {
    return `[#${seed}] ${player.first_name} ${player.last_name} (${player.ntrp_level}) #${player.id}`
  }

  const onPlayerChange = (id, seed) => {
    const newPlayer = players.find((player) => player.id === id)
    let newPlayers = groupData.players.map((player) => {
      if (player.seed !== seed) {
        return player
      } else {
        return { ...newPlayer, seed }
      }
    })
    setPlayers(newPlayers)
  }

  const addPlayer = () => {
    const newPlayers = [
      ...groupData.players,
      {
        ...blankPlayer,
        seed: groupData.players.length + 1,
      },
    ]
    setPlayers(newPlayers)
  }

  const removePlayer = (playerSeed) => {
    let newPlayers = groupData.players.filter(
      (player) => player.seed !== playerSeed
    )
    setPlayers(newPlayers)
  }

  // move player up or down in the seed order
  const updateSeed = (player, direction) => {
    if (
      player.seed + direction < 1 ||
      player.seed + direction > groupData.players.length
    ) {
      return
    }

    let newPlayers = groupData.players.map((p) => {
      if (p.seed === player.seed) {
        return { ...p, seed: p.seed + direction }
      }
      if (p.seed === player.seed + direction) {
        return { ...p, seed: p.seed - direction }
      }
      return p
    })
    setPlayers(newPlayers)
  }

  const setPlayers = (players) => {
    // sort players by seed
    players.sort((p1, p2) => p1.seed - p2.seed)

    // update playes seed values to match their index
    players.forEach((player, index) => {
      player.seed = index + 1
    })

    setGroupData({
      ...groupData,
      players,
    })
  }

  const handleSave = async () => {
    const data = {
      label: groupData.label,
      players: newPlayers.map((player) => ({
        id: player.id,
        seed: player.seed,
      })),
    }

    setInFlight(true)
    setApiResponse(null)
    try {
      const result = await stcApi.patch(`/group/${groupData.id}/update`, data)
      setApiResponse(result.data.data)
      setInFlight(false)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  if (!isAdmin) {
    return (
      <Alert severity="error">
        You don't have permission to edit this group
      </Alert>
    )
  }

  return (
    <Paper sx={{ padding: "12px" }} variant="outlined">
      <Stack spacing={2}>
        {apiResponse && (
          <Alert severity={apiResponse?.success ? "success" : "warning"}>
            {apiResponse.message ?? JSON.stringify(apiResponse)}
          </Alert>
        )}
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Group Label"
            value={groupData.label}
            onChange={updateGroupLabel}
          />
          <Button variant="outlined" disabled={inFlight} onClick={addPlayer}>
            Add Player
          </Button>
          <Button
            variant="contained"
            disabled={inFlight || !groupData.label.length || !newPlayers.length}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            color="secondary"
            disabled={inFlight}
            onClick={() => setGroupData(normalizedData)}
          >
            Reset
          </Button>
        </Stack>
        <Stack direction="column" spacing={2}>
          {groupData.players.map((player) => (
            <Stack direction="row" spacing={2} key={player.seed}>
              <Autocomplete
                options={playerIds}
                getOptionLabel={(option) => {
                  let acPlayer = players.find((player) => player.id === option)
                  return formatPlayerLabel(acPlayer, player.seed)
                }}
                fullWidth
                value={player.id}
                onChange={(e, val) => onPlayerChange(val, player.seed)}
                renderInput={(params) => (
                  <TextField {...params} label="Player" />
                )}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={(e) => removePlayer(player.seed)}
              >
                Remove
              </Button>
              <IconButton onClick={(e) => updateSeed(player, -1)}>
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton onClick={(e) => updateSeed(player, 1)}>
                <ArrowDownwardIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default Edit
