import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { Check, AddCircle, RemoveCircle } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fullName } from "../../common/player"
import { setGroupView, setMatches, updateGroup } from "../../slices/cycleSlice"
import stcApi from "../../api/stc"
import { getAxiosErrorData } from "../../common/tools"
import { getScore } from "../../common/group"
import { Box } from "@mui/system"

const Score = ({ groupId, players }) => {
  const dispatch = useDispatch()

  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)
  const [confirmRemove, setConfirmRemove] = useState(false)

  const errorMessage =
    apiResponse && apiResponse.success === false && apiResponse.message
      ? apiResponse.message
      : null

  const p1 = players[0]
  const p2 = players[1]

  // check if we have associated score
  const matchScore = getScore(useSelector, groupId, p1.id, p2.id)
  const hasScore = matchScore !== undefined

  // list of sets from player1's view, each value is array with [gamesPlayer1, gamesPlayer2]
  const [sets, setSets] = useState([["", ""]])

  // flag to switch between regular and default score
  const [isDefault, setIsDefault] = useState(false)
  const [defaultWinner, setDefaultWinner] = useState(null)

  // pre-fill score if we have associated match
  useEffect(() => {
    if (hasScore) {
      // handle defaults
      if (matchScore.is_default) {
        setIsDefault(true)
        setDefaultWinner(
          matchScore.result === 1
            ? matchScore.player1_id
            : matchScore.player2_id
        )
        return
      }

      let sets = matchScore.sets
      // reverse score if players are not is the same order
      if (matchScore.player1_id === p2.id) {
        sets = sets.map((set) => [set[1], set[0]])
      }
      setSets(sets)
    }
  }, [matchScore])

  // convert sets to array with flags (int): 1 = player 1 won, 2 = player 2 won, 0 = invalid score
  const getSetWinners = () => {
    return sets.map((set) => {
      let score1 = parseInt(set[0], 10)
      let score2 = parseInt(set[1], 10)

      // both values must be provided
      if (isNaN(score1) || isNaN(score2)) {
        return 0
      }

      // handle tie-break 7-6 or 6-7
      if (score1 === 6 && score2 === 7) {
        return 2
      }
      if (score1 === 7 && score2 === 6) {
        return 1
      }

      // otherwise the winner is whoever has higher score and won at least by 2
      if (Math.abs(score1 - score2) < 2) {
        return 0
      }

      return score1 > score2 ? 1 : 2
    })
  }

  const getLastSetWinner = () => {
    return getSetWinners().pop()
  }

  // return int: 1 = won by player 1, 2 = won by player 2, 0 = undetermined
  const getWinner = () => {
    // handle default wins
    if (isDefault) {
      return defaultWinner === p1.id ? 1 : defaultWinner === p2.id ? 2 : 0
    }

    let setsWonByPlayer = getSetsWon()
    let p1Sets = setsWonByPlayer[0]
    let p2Sets = setsWonByPlayer[1]
    // winner is whoever won most sets and won the last one
    let lastSetWinner = getLastSetWinner()
    if (p1Sets > 0 && p1Sets > p2Sets && lastSetWinner === 1) {
      return 1
    }
    if (p2Sets > 0 && p2Sets > p1Sets && lastSetWinner === 2) {
      return 2
    }
    return 0
  }

  const isScoreValid = () => {
    return getWinner() !== 0
    // return !getSetWinners().includes(0) && getWinner() !== 0
  }

  // return array with [<#sets won by p1>, <#sets won by p2>]
  const getSetsWon = () => {
    let p1setsWon = 0
    let p2SetsWon = 0
    getSetWinners().map((winner) => {
      if (winner === 1) {
        p1setsWon++
      } else if (winner === 2) {
        p2SetsWon++
      }
    })
    return [p1setsWon, p2SetsWon]
  }

  const updateSetValue = (playerIndex, setIndex, value) => {
    // value can only be digits or blank
    if (value && !/^[0-9]+$/.test(value)) {
      return
    }

    value = value ? parseInt(value, 10) : value

    let newSet = [...sets[setIndex]]
    newSet[playerIndex] = value

    // auto-fill other value if we can guess
    let otherPlayerIndex = playerIndex === 0 ? 1 : 0
    if (newSet[otherPlayerIndex] === "") {
      if (value <= 4) {
        newSet[otherPlayerIndex] = 6
      } else if (value === 5) {
        newSet[otherPlayerIndex] = 7
      }
    }

    let newSets = sets.map((set, sIdx) => (sIdx === setIndex ? newSet : set))
    setSets(newSets)
  }

  const addSet = () => {
    setSets([...sets, ["", ""]])
  }

  const removeLastSet = () => {
    let newSets = [...sets]
    newSets.pop()
    setSets(newSets)
  }

  // @todo - move this somewhere central to reuse
  const params = useParams()
  const season = params.season
  const cycleSlug = params.cycleSlug
  const fetchCycleData = async () => {
    setInFlight(true)
    try {
      const result = await stcApi.get(`/cycle/${season}/${cycleSlug}`)
      let groups = result.data.data.cycleData.groups
      dispatch(updateGroup(groups.filter((group) => group.id === groupId)[0]))
      dispatch(setMatches(result.data.data.cycleData))
      setInFlight(false)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }
  //---

  const addScore = async (data) => {
    setInFlight(true)
    setApiResponse(null)
    try {
      const result = hasScore
        ? await stcApi.put(`/match/score/${matchScore.id}`, data)
        : await stcApi.post("/match/score", data)
      setApiResponse(result.data.data)
      setInFlight(false)

      // update group data
      fetchCycleData()
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  const submitScore = () => {
    addScore({
      group_id: groupId,
      player1_id: p1.id,
      player2_id: p2.id,
      result: getWinner(),
      is_default: isDefault || null,
      sets: isDefault ? [] : sets,
    })
  }

  const removeScore = async () => {
    if (!hasScore) {
      return
    }

    setInFlight(true)
    setApiResponse(null)
    try {
      const result = await stcApi.delete(`/match/score/${matchScore.id}`)
      setApiResponse(result.data.data)
      setInFlight(false)

      // update group data
      fetchCycleData()
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  return (
    <>
      <Divider />
      <Stack spacing={2}>
        <Typography component="div" variant="h6">
          {hasScore ? "Edit" : "Enter"} Score
        </Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        {players.map((player, pIdx) => (
          <Stack
            key={player.id}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                width: "200px",
                fontWeight: "bold",
              }}
              spacing={1}
              ml={1}
            >
              <div>{fullName(player)}</div>
              <div>{getWinner() === pIdx + 1 && <Check color="success" />}</div>
            </Stack>

            {isDefault && (
              <Checkbox
                checked={defaultWinner === player.id}
                onChange={(e) => setDefaultWinner(player.id)}
              />
            )}

            {!isDefault &&
              sets.map((set, sIdx) => (
                <TextField
                  key={`${pIdx}.${sIdx}`}
                  sx={{ maxWidth: "5rem" }}
                  value={set[pIdx]}
                  label={`Set ${sIdx + 1}`}
                  onChange={(e) => updateSetValue(pIdx, sIdx, e.target.value)}
                />
              ))}
            {!isDefault && pIdx === 0 && (
              <Button onClick={addSet} startIcon={<AddCircle />}>
                Set
              </Button>
            )}
            {!isDefault && pIdx === 1 && sets.length > 1 && (
              <Button onClick={removeLastSet} startIcon={<RemoveCircle />}>
                Set
              </Button>
            )}
          </Stack>
        ))}

        {confirmRemove && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Alert severity="warning">
              <strong>Score will be removed (cannot be undone)</strong>
            </Alert>
            <Button
              color="secondary"
              onClick={(e) => setConfirmRemove(false)}
              disabled={inFlight}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={removeScore}
              disabled={inFlight}
            >
              Remove
            </Button>
          </Stack>
        )}

        {!confirmRemove && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              color="secondary"
              disabled={inFlight}
              onClick={(e) =>
                dispatch(setGroupView({ groupId, view: "table" }))
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!isScoreValid() || inFlight}
              onClick={submitScore}
            >
              {!isScoreValid()
                ? "Score is invalid"
                : hasScore
                ? "Update"
                : "Save"}
            </Button>
            <Stack direction="row" spacing={0} alignItems="center">
              <Box>Default</Box>
              <Switch
                checked={isDefault}
                onChange={(e) => setIsDefault(!isDefault)}
              />
            </Stack>
            {hasScore && (
              <Box flexGrow={1} justifyContent="flex-end" display="flex">
                <Button
                  variant="contained"
                  color="error"
                  onClick={(e) => setConfirmRemove(true)}
                  disabled={inFlight}
                >
                  Remove Score
                </Button>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    </>
  )
}

export default Score
