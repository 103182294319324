import {
  Alert,
  AlertTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Box,
  Button,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import RequireAuth from "../ui/RequireAuth"
import Title from "../ui/Title"
import PasswordConfirm from "../ui/PasswordConfirm"
import { getAxiosErrorData } from "../../common/tools"
import stcApi from "../../api/stc"
import * as auth from "../../slices/authSlice"

const UserChangePassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [error, setErrMessage] = useState(null)
  const [inFlight, setInFlight] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  const onNewPassword = (value) => {
    setNewPassword(value)
  }

  const isFormValid = () => {
    return newPassword.length > 0 && oldPassword.length > 0
  }

  const changePassword = async (data) => {
    setErrMessage(null)
    setInFlight(true)
    try {
      await stcApi.put(`/user/change-password`, data)
      setInFlight(null)
      setHasChanged(true)
      dispatch(auth.signOut())
    } catch (err) {
      let data = getAxiosErrorData(err)
      setErrMessage(data.message)
      setInFlight(null)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid()) {
      return
    }

    changePassword({
      old_password: oldPassword,
      new_password: newPassword,
    })
  }

  const afterChangeClick = (e) => {
    e.preventDefault()
    navigate("/signin")
  }

  if (hasChanged) {
    return (
      <Stack spacing={3}>
        <Title>Change Password</Title>
        <Alert severity="success">
          <AlertTitle>Your password has been successfully changed.</AlertTitle>
          You were signed out and will be required to sign-in with new password.
        </Alert>
        <Box>
          <Button type="button" variant="contained" onClick={afterChangeClick}>
            Sign-in with new password
          </Button>
        </Box>
      </Stack>
    )
  }

  return (
    <RequireAuth>
      <Stack spacing={3}>
        <Title>Change Password</Title>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error occurred when changing your password:</AlertTitle>
            {error}
          </Alert>
        )}

        <Stack
          component="form"
          direction="column"
          onSubmit={onSubmit}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Old Password"
            required
            value={oldPassword}
            disabled={inFlight}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setOldPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Alert severity="info">
            <AlertTitle>Forgot your current password?</AlertTitle>
            Sign out and click on REGISTER to reset your password.
          </Alert>

          <PasswordConfirm onNewValue={onNewPassword} isDisabled={inFlight} />

          <Stack direction="row" spacing={1}>
            <Button
              type="submit"
              variant="contained"
              disabled={!isFormValid() || inFlight}
            >
              Submit
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={(e) => navigate("/")}
              disabled={inFlight}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </RequireAuth>
  )
}

export default UserChangePassword
