import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import t from "typy"
import stcApi from "../api/stc"
import { signOut } from "./authSlice"

const initialState = {
  records: [],
}

export const fetchAllPlayers = createAsyncThunk(
  "players/fetchAll",
  async () => {
    const response = await stcApi.get("/players")
    return response.data.data.players
  }
)

const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPlayers.fulfilled, (state, action) => {
      state.records = action.payload
    })

    // clear players when signOut
    builder.addCase(signOut, (state, action) => {
      state.records = []
    })
  },
})

// helpers
const getPlayerState = (state) => state.players
export const getPlayers = (state) =>
  t(getPlayerState(state), "records").safeArray
export const getPlayerIds = (state) =>
  getPlayers(state).map((player) => player.id)

export default playersSlice.reducer
