import axios from "axios"
import t from "typy"
import { getLocalUserData, clearLocalUserData } from "../common/user"

const stcApi = axios.create({
  baseURL: "https://league-api.saanichtennisclub.org/api/v1",
})

// inject auth token if we have one in local storage
stcApi.interceptors.request.use((config) => {
  let token = t(getLocalUserData(), "auth_token").safeString
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`
  }
  return config
})

// clear user local data if token is invalid
stcApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const data = t(error, "response.data").safeObject
    const errType = t(data, "error.type").safeString
    if (errType === "UNAUTHENTICATED") {
      clearLocalUserData()
      // @todo - clear user state
    }
    return Promise.reject(error)
  }
)

export default stcApi
