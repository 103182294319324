import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Stack,
  Alert,
  AlertTitle,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Title from "../ui/Title"
import stcApi from "../../api/stc"
import { getAxiosErrorData } from "../../common/tools"
import { signIn } from "../../slices/authSlice"

const SignIn = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [inFlight, setInFlight] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authUser = async () => {
    setInFlight(true)
    setErrMessage(null)
    try {
      const result = await stcApi.get("/user/auth", {
        auth: {
          username,
          password,
        },
      })

      // update redux state with auth status if success
      if (result.data.success) {
        dispatch(signIn({ username, ...result.data.data }))
        navigate("/")
      }
      setInFlight(false)
    } catch (err) {
      let data = getAxiosErrorData(err)
      setErrMessage(data.message)
      setInFlight(null)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    authUser()
  }

  return (
    <Stack spacing={3}>
      <Title>Sign In</Title>
      {errMessage && <Alert severity="error">{errMessage}</Alert>}
      <Stack
        component="form"
        direction="column"
        onSubmit={onSubmit}
        spacing={1}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          autoFocus
          label="Username or email"
          value={username}
          disabled={inFlight}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          required
          value={password}
          disabled={inFlight}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Alert severity="info">
          <AlertTitle>Forgot your password?</AlertTitle>
          Click on REGISTER link to reset your password.
        </Alert>
        <Box>
          <Button
            type="submit"
            variant="contained"
            disabled={!username || !password || inFlight}
          >
            Sign In
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}

export default SignIn
