import { Box, Paper, Stack } from "@mui/material"

const JoiningPlayersList = ({ players }) => {
  if (!players || !players.length) {
    return null
  }

  return (
    <Paper elevation={5}>
      <Stack spacing={0} m={2}>
        <Box fontWeight="bold" mb={1}>
          Players joining next cycle:
        </Box>
        {players.map((player, index) => (
          <Box key={player.user_id}>
            {index + 1}. {player.first_name} {player.last_name} (
            {player.ntrp_level})
          </Box>
        ))}
      </Stack>
    </Paper>
  )
}

export default JoiningPlayersList
