import t from "typy"

// encode/decode strings - use base64
export const strEncode = (plainString) => btoa(plainString)
export const strDecode = (encoded) => atob(encoded)

// get data from axios error object
export const getAxiosErrorData = (err) => {
  if (err.response) {
    let data = t(err, "response.data").safeObject

    return {
      statusCode: t(data, "statusCode").safeString,
      type: t(data, "error.type").safeObjectOrEmpty,
      message: t(data, "error.description").safeString,
    }
  } else if (err.request) {
    return {
      statusCode: t(err, "request.status").safeString,
      message:
        t(err, "request.responseText").safeString ||
        t(err, "response.statusText").safeString,
    }
  } else {
    return {
      message: t(err, "message").safeString || "UNKNOWN ERROR",
    }
  }
}

// helper method to fetch data from api and redirect to error page if unauthorized
export const handleApiError = (axiosErr, navigate) => {
  let errData = getAxiosErrorData(axiosErr)
  navigate("/error", { state: errData })
}
