import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  IconButton,
} from "@mui/material"
import { AccountCircle } from "@mui/icons-material"
import Title from "../ui/Title"
import stcApi from "../../api/stc"
import { signIn } from "../../slices/authSlice"
import { getPlayers } from "../../slices/playersSlice"

const PlayersList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const players = useSelector(getPlayers)

  const actionImpersonate = async (userId, email) => {
    const result = await stcApi.get(`/admin/impersonate/${userId}`)

    // update redux state with auth status if success which will cause login as the user
    if (result.data.success) {
      dispatch(signIn({ username: email, ...result.data.data }))
      navigate("/")
    }
  }

  const renderActions = (userId, email) => {
    return (
      <Stack direction="row" spacing={2}>
        {userId && (
          <IconButton
            color="primary"
            onClick={(e) => actionImpersonate(userId, email)}
          >
            <AccountCircle />
          </IconButton>
        )}
      </Stack>
    )
  }

  const renderTable = () => {
    if (!players) {
      return null
    }

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>UserID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>NTRP</TableCell>
              <TableCell>Member</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map(
              ({
                id,
                user_id,
                first_name,
                last_name,
                gender,
                phone,
                email,
                ntrp_level,
                is_member,
              }) => (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{id}</TableCell>
                  <TableCell>{user_id}</TableCell>
                  <TableCell>{first_name}</TableCell>
                  <TableCell>{last_name}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{phone}</TableCell>
                  <TableCell>{gender}</TableCell>
                  <TableCell>{ntrp_level}</TableCell>
                  <TableCell>{is_member}</TableCell>
                  <TableCell>{renderActions(user_id, email)}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Stack spacing={3}>
      <Title>Players List</Title>
      {renderTable()}
    </Stack>
  )
}

export default PlayersList
