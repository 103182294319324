import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { Alert, AlertTitle } from "@mui/material"
import * as auth from "../../slices/authSlice"

const Admin = () => {
  const isAdmin = useSelector(auth.isAdmin)

  if (!isAdmin) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        Missing admin privileges
      </Alert>
    )
  }

  return <Outlet />
}

export default Admin
