import t from "typy"
import * as cycle from "../slices/cycleSlice"
import * as auth from "../slices/authSlice"

export const getGroup = (select, groupId) =>
  select(cycle.getGroups).find((group) => group.id === groupId) || {}

export const getGroupPlayers = (select, groupId) =>
  t(getGroup(select, groupId), "players").safeArray

export const getScore = (select, groupId, player1Id, player2Id) => {
  let matches = select(cycle.getMatches)
  return matches.find(
    (match) =>
      match.group_id === groupId &&
      ((match.player1_id === player1Id && match.player2_id === player2Id) ||
        (match.player1_id === player2Id && match.player2_id === player1Id))
  )
}

// return true if currently authenticated player plays in given group
export const isParticipating = (select, groupId) => {
  let playerId = select(auth.getSignedPlayerId)
  let group = select(cycle.getGroups).find((g) => g.id === groupId)
  return group.players.find((p) => p.id === playerId) !== undefined
}
