import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Title from "../ui/Title"
import * as auth from "../../slices/authSlice"
import * as cycle from "../../slices/cycleSlice"
import * as cycles from "../../slices/cyclesSlice"
import { useSelector } from "react-redux"
import RequireAuth from "../ui/RequireAuth"
import { Box } from "@mui/system"
import { getAxiosErrorData } from "../../common/tools"
import stcApi from "../../api/stc"
import { useNavigate } from "react-router-dom"

const UserProfile = () => {
  const navigate = useNavigate()

  const [isJoining, setIsJoining] = useState(null)
  const [isWithdrawing, setIsWithdrawing] = useState(null)
  const [error, setError] = useState(null)
  const [inFlight, setInFlight] = useState(false)
  const [changesApplied, setChangesApplied] = useState(null)

  const linkedPlayer = useSelector(auth.getSignedPlayerData)
  const { first_name, last_name, ntrp_level, is_member, email } = linkedPlayer
  const isMember = is_member === "Y"

  const isParticipating = useSelector(cycle.isParticipating)
  const activeCycle = useSelector(cycles.getActiveCycle)

  const applyUserSettings = (settings) => {
    setIsJoining(
      parseInt(settings.is_joining_cycle_id) === parseInt(activeCycle?.id)
    )
    setIsWithdrawing(
      parseInt(settings.is_withdrawing_cycle_id) === parseInt(activeCycle?.id)
    )
  }

  const fetchSettings = async () => {
    setError(null)
    setInFlight(true)
    try {
      const result = await stcApi.get(`/user/settings`)
      applyUserSettings(result.data.data.userProps)
      setInFlight(false)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setError(data.message)
      setInFlight(false)
    }
  }

  const onJoinChange = async () => {
    setError(null)
    setInFlight(true)
    try {
      const result = await stcApi.patch(`/user/settings`, {
        is_joining_next_cycle: !isJoining,
      })
      applyUserSettings(result.data.data.userProps)
      setInFlight(false)
      setChangesApplied(true)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setError(data.message)
      setInFlight(false)
      setChangesApplied(false)
    }
  }

  const onWithdrawChange = async () => {
    setError(null)
    setInFlight(true)
    try {
      const result = await stcApi.patch(`/user/settings`, {
        is_withdrawing_next_cycle: !isWithdrawing,
      })
      applyUserSettings(result.data.data.userProps)
      setInFlight(false)
      setChangesApplied(true)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setError(data.message)
      setInFlight(false)
      setChangesApplied(false)
    }
  }

  useEffect(() => {
    fetchSettings()
  }, [])

  const renderStcStatus = () => {
    if (!isMember) {
      return (
        <Alert severity="warning">
          <AlertTitle>STC Membership not found</AlertTitle>
          We cannot find STC membership matching your email{" "}
          <strong>{email}</strong>. You can sign up{" "}
          <a
            href="https://signup.saanichtennisclub.org/membership"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .<br />
        </Alert>
      )
    }

    return (
      <Alert severity="success">
        <AlertTitle>STC Member</AlertTitle>
        Your account is linked to STC membership matching your email{" "}
        <strong>{email}</strong>.
      </Alert>
    )
  }

  const renderParticipatingStatus = () => {
    if (!isParticipating && isJoining) {
      return (
        <Alert severity="info">
          <AlertTitle>You are currently not playing in the league.</AlertTitle>
          You will be included in the next cycle.
        </Alert>
      )
    }

    if (!isParticipating) {
      return (
        <Alert severity="warning">
          <AlertTitle>You are currently not playing in the league.</AlertTitle>
          You can <strong>join</strong> from the next cycle below.
        </Alert>
      )
    }

    return (
      <Alert severity={isWithdrawing ? "info" : "success"}>
        <AlertTitle>You are currently playing in the league.</AlertTitle>
        {isWithdrawing && (
          <Box>
            You will <strong>not be included</strong> in the next cycle as per
            your request. You can change it below.
          </Box>
        )}
        {!isWithdrawing && (
          <Box>
            You will be automatically included in the next cycle, unless you
            explicitly withdraw yourself below, or will{" "}
            <strong>not complete any matches</strong> in the current cycle.
          </Box>
        )}
      </Alert>
    )
  }

  const renderParticipatingOptions = () => {
    let joinNext = () => {
      if (isParticipating) {
        return null
      }
      return (
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center">
            <Box flexGrow={1} fontWeight="bold">
              Join from next cycle
            </Box>
            <Switch
              disabled={isParticipating || inFlight}
              checked={isJoining}
              onChange={onJoinChange}
            />
          </Stack>
          <Typography sx={{ color: "#555", fontSize: "90%" }}>
            If selected, you will be inlcuded in the next cycle. Cycles start at
            the beginning of each calendar month.
          </Typography>
        </Stack>
      )
    }

    let withdraw = () => {
      if (!isParticipating) {
        return null
      }
      return (
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center">
            <Box flexGrow={1} fontWeight="bold">
              Withdraw from league
            </Box>
            <Switch
              disabled={!isParticipating || inFlight}
              checked={isWithdrawing}
              onChange={onWithdrawChange}
            />
          </Stack>
          <Typography sx={{ color: "#555", fontSize: "90%" }}>
            If selected, you will not be included in the next cycle. All your
            unplayed matches will be defaulted at the end of current cycle.
          </Typography>
        </Stack>
      )
    }

    return (
      <Stack spacing={3}>
        {joinNext()}
        {withdraw()}
      </Stack>
    )
  }

  return (
    <RequireAuth>
      <Stack spacing={3}>
        {first_name && (
          <Title>
            User Profile - {`${first_name} ${last_name} (${ntrp_level}) `}
            {!isMember && <Chip label="trial" color="primary" />}
          </Title>
        )}
        {error && (
          <Alert severity="error">
            <AlertTitle>Unable to get or update your settings:</AlertTitle>
            {error}
          </Alert>
        )}

        {renderStcStatus()}

        <Typography variant="h6">League participation</Typography>
        {!activeCycle && (
          <Alert severity="error">
            <AlertTitle>There is no active cycle.</AlertTitle>
            This usually means that league is currently not in progress. Wait
            until it resumes, or contact league coordinator.
          </Alert>
        )}
        {activeCycle && renderParticipatingStatus()}
        {activeCycle && renderParticipatingOptions()}

        <Stack
          direction="row"
          pt={5}
          spacing={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          {inFlight && isJoining === null && <Box>Checking settings...</Box>}
          {inFlight && isJoining !== null && <Box>Applying changes...</Box>}
          {!inFlight && changesApplied === null && (
            <Box color="#999">No changes made</Box>
          )}
          {!inFlight && changesApplied === true && <Box>All changes saved</Box>}
          {!inFlight && changesApplied === false && (
            <Box>Some changes may not be saved</Box>
          )}
          <Button
            variant="contained"
            onClick={(e) => navigate("/")}
            disabled={inFlight}
          >
            Return to main page
          </Button>
        </Stack>
      </Stack>
    </RequireAuth>
  )
}

export default UserProfile
