import Title from "../ui/Title"
import { useSelector, useDispatch } from "react-redux"
import {
  addGroup,
  setLabel,
  setGroups,
  setMode,
  setSlug,
} from "../../slices/cycleSlice"
import GroupEdit from "../group/GroupEdit"
import { Button, Stack, TextField, Box } from "@mui/material"
import { useEffect, useState } from "react"
import stcApi from "../../api/stc"
import { getAxiosErrorData } from "../../common/tools"

const NewCycle = () => {
  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const state = useSelector((state) => state.cycle)
  const dispatch = useDispatch()

  // reset cycle view on first load
  // @todo - merge later with edit based on redux cycle state
  useEffect(() => {
    dispatch(setGroups({ groups: [] }))
    dispatch(setMode("add"))
  }, [])

  // @todo - should only render this component when this is true
  if (state.mode !== "add") {
    return null
  }

  const renderGroups = () => {
    return state.groups.map((group) => (
      <GroupEdit key={group.id} groupId={group.id} />
    ))
  }

  const updateCycleLabel = (e) => {
    dispatch(setLabel({ label: e.target.value }))
  }

  const updateCycleSlug = (e) => {
    dispatch(setSlug({ slug: e.target.value }))
  }

  // remove empty groups and empty players
  const cleanCycle = () => {
    let groups = state.groups
      .map((group) => {
        let players = !group.players
          ? null
          : group.players.filter((player) => player.id !== null)
        return { ...group, players }
      })
      .filter((group) => group.players && group.players.length > 0)

    dispatch(setGroups({ groups }))
  }

  const addCycle = async (data) => {
    setInFlight(true)
    setApiResponse(null)
    try {
      const result = await stcApi.post("/cycle/add", data)
      setApiResponse(result.data.data)
      setInFlight(false)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  const submitCycle = (e) => {
    addCycle({
      label: state.label,
      slug: state.slug,
      groups: state.groups,
    })
  }

  return (
    <>
      <Stack spacing={3}>
        <Title>Add new cycle</Title>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Cycle Label"
            value={state.cycleLabel}
            onChange={updateCycleLabel}
          />
          <TextField
            label="Cycle Slug"
            value={state.slug}
            onChange={updateCycleSlug}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={(e) => dispatch(addGroup())}>
                Add Group
              </Button>
              <Button variant="outlined" onClick={cleanCycle}>
                Clean
              </Button>
            </Stack>
          </Box>
          <Button variant="contained" onClick={submitCycle} disabled={inFlight}>
            Submit
          </Button>
        </Stack>

        {renderGroups()}
      </Stack>
      <pre>{JSON.stringify(apiResponse, 0, 1)}</pre>
    </>
  )
}

export default NewCycle
