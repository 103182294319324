import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Select, MenuItem, Typography, Stack } from "@mui/material"
import { getCycles, getSeasons } from "../../slices/cyclesSlice"
import * as cycle from "../../slices/cycleSlice"

const CycleSelector = () => {
  const navigate = useNavigate()

  const cyclesData = useSelector(getCycles)
  const currentSeason = useSelector(cycle.getSeason)
  const currentCycleId = useSelector(cycle.getId)
  const seasons = useSelector(getSeasons)

  const [season, setSeason] = useState("")

  useEffect(() => {
    setSeason(currentSeason)
  }, [currentSeason])

  if (cyclesData.length < 2 || !season || !seasons.includes(season)) {
    return null
  }

  // get list of cycles for the season
  let cycles = cyclesData.filter((c) => c.season === season)
  let selectedCycleId = cycles.find((c) => parseInt(c.id) === currentCycleId)
    ? currentCycleId
    : ""

  const onCycleSelect = (e) => {
    let selectedCycle = cyclesData.find((c) => c.id === e.target.value)
    if (selectedCycle) {
      navigate(`/${selectedCycle.season}/${selectedCycle.slug}`)
    }
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Select
        size="small"
        label="Season"
        value={season}
        onChange={(e) => setSeason(e.target.value)}
      >
        {seasons.map((season) => (
          <MenuItem key={season} value={season}>
            {season}
          </MenuItem>
        ))}
      </Select>
      <Typography>/</Typography>
      <Select
        size="small"
        label="Cycle"
        value={selectedCycleId}
        onChange={onCycleSelect}
      >
        {cycles.map((c) => (
          <MenuItem key={c.id} value={c.id}>
            {c.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}

export default CycleSelector
