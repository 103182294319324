import { Button, Stack, Typography, IconButton, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch, useSelector } from "react-redux"
import StyledCell from "./StyledCell"
import * as cycle from "../../slices/cycleSlice"
import * as auth from "../../slices/authSlice"
import { getGroup, getScore } from "../../common/group"
import { AddCircle } from "@mui/icons-material"

const PlayerCell = ({ rowPlayer, colPlayer, groupId, points }) => {
  const dispatch = useDispatch()

  const group = getGroup(useSelector, groupId)
  const isActiveCycle = useSelector(cycle.isActive)
  const signedPlayerId = useSelector(auth.getSignedPlayerId)
  const isRowPlayerAuth = signedPlayerId === rowPlayer.id
  const areSamePlayers = rowPlayer.id === colPlayer.id

  // check if we have associated match
  const matchScore = getScore(useSelector, groupId, rowPlayer.id, colPlayer.id)
  const hasScore = matchScore !== undefined

  const setScoreView = () => {
    dispatch(
      cycle.updateGroup({
        ...group,
        view: "score",
        scoreForPlayers: [rowPlayer, colPlayer],
      })
    )
  }

  const renderScore = () => {
    // check if player1 in score matches rowPlayer, if so then set reverse to false, otherwise true
    let isReverse = rowPlayer.id === matchScore.player2_id

    // assemble score from sets
    let setScores = matchScore.sets.map((set) =>
      isReverse ? `${set[1]}-${set[0]}` : `${set[0]}-${set[1]}`
    )

    // get outcome: win/loss/default
    let isDefault = matchScore.is_default !== null
    let lossLabel = isDefault ? "D" : "L"
    let winLabel = isDefault ? "W*" : "W"
    let outcome = isReverse
      ? matchScore.result === 1
        ? lossLabel
        : winLabel
      : matchScore.result === 1
      ? winLabel
      : lossLabel

    return (
      <Typography>
        <strong>{`${outcome} `}</strong>
        {setScores.join(", ")}
        {isActiveCycle && isRowPlayerAuth && (
          <Tooltip title="Edit result">
            <IconButton onClick={setScoreView}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
    )
  }

  if (areSamePlayers) {
    return (
      <StyledCell
        sx={{
          backgroundColor: "#eee",
          color: "#aaa",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="baseline">
          <Typography
            sx={{
              fontSize: "170%",
              fontWeight: "bold",
              color: "#999",
            }}
          >
            {points}
          </Typography>
          <Typography>{"PT" + (points === 1 ? "" : "S")}</Typography>
        </Stack>
      </StyledCell>
    )
  }

  return (
    <StyledCell>
      {hasScore && renderScore()}
      {isActiveCycle && !hasScore && isRowPlayerAuth && (
        <Button onClick={setScoreView} startIcon={<AddCircle />}>
          Score
        </Button>
      )}
    </StyledCell>
  )
}

export default PlayerCell
