import { Divider, Stack, Box } from "@mui/material"

const Footer = () => {
  return (
    <Stack
      my={3}
      spacing={2}
      sx={{
        color: "#777",
      }}
    >
      <Divider />
      <Stack direction="row">
        <Box flexGrow={1}>© 2024 Saanich Tennis Club Singles League</Box>
        <Box sx={{ color: "#aaa", fontSize: "90%" }}>
          (<strong>v.</strong> &#946;)
        </Box>
      </Stack>
    </Stack>
  )
}

export default Footer
