import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Tooltip,
  Menu,
  MenuItem,
  Stack,
  Container,
  IconButton,
} from "@mui/material"
import { ArrowDropDown, HelpOutline } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import * as auth from "../../slices/authSlice"

const NavBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(auth.isAuthenticated)
  const isAdmin = useSelector(auth.isAdmin)
  const username = useSelector(auth.getUsername)
  const fullName = useSelector(auth.getFullName)

  const [anchorMenu, setAnchorMenu] = useState(null)

  const clickSignOut = () => {
    handleClose()
    dispatch(auth.signOut())
    navigate("/")
  }

  const clickProfile = () => {
    handleClose()
    navigate("/user/profile")
  }

  const clickChangePassword = () => {
    handleClose()
    navigate("/user/change-password")
  }

  const handleClose = () => {
    setAnchorMenu(null)
  }

  const handleMenu = (e) => {
    setAnchorMenu(e.currentTarget)
  }

  const renderActionsForAnonymous = () => {
    return (
      <>
        <Button color="inherit" onClick={() => navigate("/signin")}>
          Sign In
        </Button>
        <Button color="inherit" onClick={() => navigate("/user/register")}>
          Register
        </Button>
      </>
    )
  }

  const renderActions = () => {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <Button color="inherit" onClick={handleMenu}>
            {fullName || username}
            <ArrowDropDown />
          </Button>
        </Tooltip>
        <Menu
          sx={{ mt: "2rem" }}
          id="usermenu-appbar"
          anchorEl={anchorMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorMenu)}
          onClose={handleClose}
        >
          <MenuItem onClick={clickProfile}>Profile</MenuItem>
          <MenuItem onClick={clickChangePassword}>Change Password</MenuItem>
          <MenuItem onClick={clickSignOut}>Sign Out</MenuItem>
        </Menu>
      </Box>
    )
  }

  const renderAdminToolbar = () => {
    return (
      <Container maxWidth="lg">
        <Stack direction="row" spacing={2}>
          <Button onClick={() => navigate("/admin/players")}>Players</Button>
          <Button onClick={() => navigate("/admin/new-cycle")}>
            New Cycle
          </Button>
          <Button onClick={() => navigate("/admin/email")}>Email</Button>
          <Button onClick={() => navigate("/admin/trials")}>Trials</Button>
        </Stack>
      </Container>
    )
  }

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              "&.MuiToolbar-gutters": {
                padding: 0,
              },
            }}
          >
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <Button
                onClick={() => navigate("/")}
                sx={{ color: "white", fontSize: 22 }}
              >
                STC League
              </Button>
            </Typography>
            <Box>
              <Tooltip title="Guides / FAQ">
                <IconButton color="inherit" onClick={() => navigate("/docs")}>
                  <HelpOutline />
                </IconButton>
              </Tooltip>
            </Box>
            {isAuthenticated && renderActions()}
            {!isAuthenticated && renderActionsForAnonymous()}
          </Toolbar>
        </Container>
      </AppBar>
      {isAdmin && renderAdminToolbar()}
    </>
  )
}

export default NavBar
