import {
  Stack,
  Tabs,
  Tab,
  Box,
  Typography,
  Alert,
  Chip,
  Switch,
} from "@mui/material"
import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import t from "typy"
import Title from "../ui/Title"

const existingSections = ["user-registration", "joining-league"]

const Docs = () => {
  const navigate = useNavigate()
  const params = useParams()
  const section = t(params, "section").safeString

  // redirect if section doesn't exist
  useEffect(() => {
    if (!existingSections.includes(section)) {
      navigate("/docs/" + existingSections[0])
    }
  }, [section, navigate])

  if (!existingSections.includes(section)) {
    return null
  }

  return (
    <Stack spacing={3}>
      <Title>Guides / faq</Title>
      <Box
        flexGrow={1}
        sx={{
          display: "flex",
        }}
      >
        <Tabs
          orientation="vertical"
          value={section}
          onChange={(e, value) => navigate(`/docs/${value}`)}
          sx={{ borderRight: 1, borderColor: "divider", minWidth: "20ch" }}
        >
          <Tab value="user-registration" label="User registration" />
          <Tab value="joining-league" label="Joining League" />
        </Tabs>

        {/* === USER REGISTRATION === */}
        <div hidden={section !== "user-registration"}>
          <Box px={2}>
            <Typography variant="h6">User Registration</Typography>
            <p>
              Click on <strong>REGISTER</strong> in the navigation on top and
              provide your email address. You will receive email from{" "}
              <code>STC League Admin (admin@stc-league.com)</code> with a link
              to set your password.
            </p>
            <p>
              If your email is not matching any of the registered STC members,
              you will be asked to provide your contact details and NTRP level.
            </p>
            <Alert severity="info">
              Your contact details will only be shown to other players
              participating in the league, so they can contact you to schedule
              matches.
            </Alert>
          </Box>
        </div>

        {/* === JOINING LEAGUE === */}
        <div hidden={section !== "joining-league"}>
          <Box px={2}>
            <Typography variant="h6">Joining League</Typography>
            <Box mt={2}>
              <Chip label="1" sx={{ fontWeight: "bold" }} />{" "}
              <strong>Create your user account</strong> by clicking on REGISTER
              in the navigation on top and follow the instructions. If you are
              already registered, skip this step.
              <Box mt={1}>
                <Alert severity="warning">
                  If you are STC member, please use the same email address you
                  registered the STC membership with.
                </Alert>
              </Box>
            </Box>
            <Box mt={2}>
              <Chip label="2" sx={{ fontWeight: "bold" }} /> Sign into your
              account, then click on your name in the navigation on top and
              select <strong>Profile</strong>. Under{" "}
              <strong>Join from next cycle</strong> click the switch button{" "}
              <Switch checked={true} /> on the right.
              <Alert severity="info">
                This option is not shown if you are currently playing in the
                league.
              </Alert>
              You will be listed on the main page with cycle details under{" "}
              <strong>Players joining next cycle</strong>.
            </Box>
            <Box mt={2}>
              <Chip label="3" sx={{ fontWeight: "bold" }} />
              You will be included in the next cycle. Cycles start at the
              beginning of each calendar month.
            </Box>
          </Box>
        </div>
      </Box>
    </Stack>
  )
}

export default Docs
