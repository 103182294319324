import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
} from "@mui/material"
import StyledCell from "./StyledCell"
import PlayerRow from "./PlayerRow"
import { fName } from "../../common/player"
import Contacts from "./Contacts"
import { useDispatch, useSelector } from "react-redux"
import Score from "./Score"
import Email from "./Email"
import { useCallback } from "react"
import * as auth from "../../slices/authSlice"
import * as cycle from "../../slices/cycleSlice"
import * as groupUtils from "../../common/group"
import Edit from "./Edit"

const Group = ({ data }) => {
  const dispatch = useDispatch()
  const view = data.view || "table"

  const isAuthenticated = useSelector(auth.isAuthenticated)
  const isAdmin = useSelector(auth.isAdmin)
  const matches = useSelector(cycle.getMatches)
  const pointsForWin = useSelector(cycle.getPointsForWin)
  const pointsForLoss = useSelector(cycle.getPointsForLoss)
  const pointsForDefault = useSelector(cycle.getPointsForDefault)
  const isParticipating = groupUtils.isParticipating(useSelector, data.id)

  // function to calculate points for a given player
  const getPlayerPoints = useCallback(
    (playerId) => {
      let pts = 0
      matches.forEach((match) => {
        if (match.player1_id === playerId) {
          pts +=
            match.result === 1
              ? pointsForWin
              : match.is_default
              ? pointsForDefault
              : pointsForLoss
        }
        if (match.player2_id === playerId) {
          pts +=
            match.result === 2
              ? pointsForWin
              : match.is_default
              ? pointsForDefault
              : pointsForLoss
        }
      })
      return pts
    },
    [matches, pointsForWin, pointsForLoss, pointsForDefault]
  )

  // sort players by score
  const players = [...data.players].sort(
    (p1, p2) => getPlayerPoints(p2.id) - getPlayerPoints(p1.id)
  )

  const renderTable = () => {
    return (
      <Table
        sx={{
          border: 1,
          tableLayout: "fixed",
        }}
      >
        <TableHead>
          <TableRow>
            <StyledCell />
            {players.map((player) => (
              <StyledCell key={player.id}>{fName(player)}</StyledCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {players.map((player) => (
            <PlayerRow
              key={player.id}
              id={player.id}
              players={players}
              groupId={data.id}
              points={getPlayerPoints(player.id)}
            />
          ))}
        </TableBody>
      </Table>
    )
  }

  return (
    <Paper elevation={10} sx={{ padding: "12px" }}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" component="div" flexGrow={1}>
            {data.label}
          </Typography>

          <Tabs
            value={view}
            onChange={(e, newValue) =>
              dispatch(cycle.updateGroup({ ...data, view: newValue }))
            }
            aria-label="group view"
          >
            {view === "score" && <Tab value="score" label="Score" />}
            <Tab value="table" label="Table" />
            {isAuthenticated && <Tab value="contacts" label="Contacts" />}
            {isParticipating && <Tab value="email" label="Email" />}
            {isAdmin && <Tab value="editGroup" label="Edit" />}
          </Tabs>
        </Stack>

        {view === "table" && renderTable()}
        {view === "contacts" && isAuthenticated && (
          <Contacts players={players} />
        )}
        {view === "email" && isParticipating && <Email groupId={data.id} />}
        {view === "score" && (
          <Score groupId={data.id} players={data.scoreForPlayers} />
        )}
        {view === "editGroup" && <Edit data={data} />}
      </Stack>
    </Paper>
  )
}

export default Group
