import { Stack, Typography, Alert } from "@mui/material"
import { useLocation } from "react-router-dom"

const Error = () => {
  const { state } = useLocation()
  const { statusCode, type, message } = state

  return (
    <Stack spacing={2}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          color: "red",
          fontWeight: "bold",
        }}
      >
        {`${statusCode}: ${type}`}
      </Typography>
      <Alert severity="error">{message}</Alert>
    </Stack>
  )
}

export default Error
