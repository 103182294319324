import { useEffect, useState } from "react"
import stcApi from "../../api/stc"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material"

const Trials = () => {
  const [data, setData] = useState([])

  const fetchTrials = async () => {
    const result = await stcApi.get(`/cycle/trials`)
    setData(result.data.data)
  }

  const getMember = (email) => {
    if (!data) {
      return null
    }
    return data.members.find((member) => member.email === email)
  }

  const handleUpdateClick = async (trialId, memberId) => {
    try {
      await stcApi.post("/cycle/trial/update", {
        trial_id: trialId,
        member_id: memberId,
      })
      fetchTrials()
    } catch (err) {
      console.error(err)
    }
  }

  const renderTrial = (trial) => {
    const member = getMember(trial.email)

    return (
      <TableRow key={trial.id}>
        <TableCell>{trial.id}</TableCell>
        <TableCell>
          {trial.first_name} {trial.last_name}
        </TableCell>
        <TableCell>{trial.email}</TableCell>
        <TableCell>{member ? member.id : "-"}</TableCell>
        <TableCell>
          {member && (
            <Button
              color="primary"
              onClick={() => handleUpdateClick(trial.id, member.id)}
            >
              Update
            </Button>
          )}
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    fetchTrials()
  }, [])

  return (
    <div>
      <h1>Trials</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Player</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Member ID</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{data.trials?.map(renderTrial)}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Trials
