import { Button, Paper, Stack, TextField, Autocomplete } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { removeGroup, updateGroup } from "../../slices/cycleSlice"
import { getPlayers, getPlayerIds } from "../../slices/playersSlice"
import { getGroup, getGroupPlayers } from "../../common/group"

const GroupEdit = ({ groupId }) => {
  const dispatch = useDispatch()

  const group = getGroup(useSelector, groupId)
  const groupPlayers = getGroupPlayers(useSelector, groupId)

  const players = useSelector(getPlayers)
  const playerIds = useSelector(getPlayerIds)

  const nextPlayerSeed = groupPlayers.length + 1

  const updateGroupLabel = (e) => {
    dispatch(updateGroup({ ...group, label: e.target.value }))
  }

  const addPlayer = () => {
    dispatch(
      updateGroup({
        ...group,
        players: [...groupPlayers, { seed: nextPlayerSeed, id: null }],
      })
    )
  }

  const removePlayer = (playerSeed) => {
    let newPlayers = groupPlayers.filter((player) => player.seed !== playerSeed)
    dispatch(updateGroup({ ...group, players: newPlayers }))
  }

  const onPlayerChange = (id, seed) => {
    let newPlayers = groupPlayers.map((player) => {
      if (player.seed !== seed) {
        return player
      } else {
        return { ...player, id }
      }
    })
    dispatch(updateGroup({ ...group, players: newPlayers }))
  }

  const formatPlayerLabel = (player) => {
    return `${player.first_name} ${player.last_name} (${player.ntrp_level}) #${player.id}`
  }

  return (
    <>
      <Paper sx={{ padding: "12px" }} variant="outlined">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="Group Label"
              value={group.label}
              onChange={updateGroupLabel}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={(e) => dispatch(removeGroup({ id: groupId }))}
            >
              Remove
            </Button>
            <Button variant="outlined" onClick={addPlayer}>
              Add Player
            </Button>
          </Stack>
          <Stack direction="column" spacing={2}>
            {groupPlayers.map((player) => (
              <Stack direction="row" spacing={2} key={player.seed}>
                <Autocomplete
                  options={playerIds}
                  getOptionLabel={(option) => {
                    let player = players.find((player) => player.id === option)
                    return formatPlayerLabel(player)
                  }}
                  fullWidth
                  value={player.id}
                  onChange={(e, val) => onPlayerChange(val, player.seed)}
                  renderInput={(params) => (
                    <TextField {...params} label="Player" />
                  )}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => removePlayer(player.seed)}
                >
                  Remove
                </Button>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}

export default GroupEdit
