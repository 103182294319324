import { Alert, Button, Stack, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import Title from "../ui/Title"
import stcApi from "../../api/stc"
import { getAxiosErrorData } from "../../common/tools"
import { useSelector } from "react-redux"
import { getPlayerIds } from "../../slices/cycleSlice"
import { getPlayers } from "../../slices/playersSlice"

const AdminEmail = () => {
  const cyclePlayerIds = useSelector(getPlayerIds)
  const playersData = useSelector(getPlayers)

  const [replyTo, setReplyTo] = useState("")
  const [to, setTo] = useState("")
  const [cc, setCc] = useState("")
  const [bcc, setBcc] = useState("")
  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("")

  const [inFlight, setInFlight] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const sendEmail = async (data) => {
    setInFlight(true)
    setApiResponse(null)
    try {
      const result = await stcApi.post("/admin/email/send", data)
      setApiResponse({ success: true, message: result.data.data.message })
      setInFlight(false)
    } catch (err) {
      const data = getAxiosErrorData(err)
      setApiResponse({
        success: false,
        message: data.message,
      })
      setInFlight(false)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    sendEmail({
      to,
      replyTo,
      cc,
      bcc,
      subject,
      body,
    })
  }

  const fillCycleEmails = () => {
    let emails = playersData
      .filter((player) => cyclePlayerIds.includes(player.id))
      .map((player) => player.email)
    setTo(emails.join(","))
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box flexGrow={1}>
          <Title>Admin Email</Title>
        </Box>
        <Button onClick={fillCycleEmails}>Fill cycle emails</Button>
      </Stack>
      {apiResponse && (
        <Alert severity={apiResponse.success ? "success" : "error"}>
          {apiResponse.message}
        </Alert>
      )}
      <Stack
        component="form"
        onSubmit={onSubmit}
        spacing={1}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Reply-To"
          value={replyTo}
          onChange={(e) => setReplyTo(e.target.value)}
        />
        <TextField
          label="To"
          value={to}
          onChange={(e) => setTo(e.target.value)}
        />
        <TextField
          label="Cc"
          value={cc}
          onChange={(e) => setCc(e.target.value)}
        />
        <TextField
          label="Bcc"
          value={bcc}
          onChange={(e) => setBcc(e.target.value)}
        />
        <TextField
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          label="Email Body"
          multiline
          minRows={10}
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
        <Box>
          <Button
            type="submit"
            variant="contained"
            disabled={!body || !subject || inFlight}
          >
            Send
          </Button>
        </Box>
        <pre>{JSON.stringify(apiResponse)}</pre>
      </Stack>
    </Stack>
  )
}

export default AdminEmail
